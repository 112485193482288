<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-row no-gutters>
            <v-col>
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode,'margin-bottom':'10px' }">{{ $store.getters.getTextMap().oee_by_day }} ({{ $store.getters.getTextMap().last_7_days }})</h2>
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-simple-table fixed-header dense :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}" >
                        <template v-slot:default>
                            <thead :style="{'background-color':$store.getters.getColorPalette().backgroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                <tr>
                                    <th :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().machines}}</th>
                                    <th v-for="(item,ind) in dateList"  :key="ind" :class="' text-center  text-subtitle-1 text-capitalize '" :style="{'background-color':$store.getters.getColorPalette().foregroundColorCode, 'color':$store.getters.getColorPalette().accentCode}">{{item}}</th>
                                </tr>
                            </thead>
                            <tbody :style="{'background-color':$store.getters.getColorPalette().background2ColorCode}">
                                <tr v-for="(machine,ind) in machineList"  :key="ind" :style="{'background-color':$store.getters.getColorPalette().background2ColorCode, 'color':$store.getters.getColorPalette().accentCode}">
                                    <td  class="text-center">{{ getMachineName(machine)}}</td>
                                    <td  v-for="(item,ind) in dateList"  :key="ind" class="text-center">
                                        <span :style="{'background-color':getCellColor(oeeData && oeeData[machine] && oeeData[machine][item] ? oeeData[machine][item]:null),'padding':'5px 30px','border-radius':'5px','color':'white'}">{{oeeData && oeeData[machine] && oeeData[machine][item] ? oeeData[machine][item]:'-'}}%</span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import moment from 'moment'
export default {
    name: 'OEEMachineWiseHeatmap',
    props: ["shift", "machines", "from_date", "to_date"],
    components: {
        InfoAlert,
    },
    data() {
        return {
            showDismissibleAlert: false,
            loading:false,
            info: '',
            oeeData: [],
            dateList: [],
            machineList: [],
            oeeTarget:80,
            selectedMetric: ['avg_performance','avg_quality','avg_availability'],
            selectedMetricNameMap: {
                'avg_performance':"Performance",
                'avg_quality':"Quality",
                'avg_availability':"Availability",
            },
            metricsOption: [
                {label:"Performance",value:"avg_performance"},
                {label:"Quality",value:"avg_quality"},
                {label:"Availability",value:"avg_availability"},
            ],
        }
    },
    mounted() {
        this.getOEEData()
    },
    methods: {
        getMachineName(machine_id) {
            let machineDetails = this.$store.getters.getMachineById(machine_id)
            let machineName=machineDetails && machineDetails.name?machineDetails.name:machine_id
            return machineName
        },
        getCellColor(oee) {
            if (oee) {
                if (oee < this.oeeTarget) {
                    return '#C62828'
                }
                return '#00E676'
            }
            return '#BDBDBD'
        },
        getOEEData() {
            this.loading = true;
            const payload = {
                machines: this.machines,
                shift: this.shift,
                from_date: moment().subtract(7,'days').format('YYYYMMDD'),
                // from_date: this.from_date,
                to_date: this.to_date,
                getAvgData: true,
                columns:['date','machine_id']
           };
            axios.post(this.$store.state.api + "getAvgerageOEEDataBetweenDate", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
            .then(response => {
                if (response.data.status == "success") {
                    let dbData = response.data.data;
                    let temp = {}
                    let machineListTemp=new Set([])
                    let dateListTemp=new Set([])
                    for (let i of dbData) {
                        machineListTemp.add(i.machine_id)
                        dateListTemp.add(moment(i.date).format("DD-MM-YYYY"))
                        if (!temp[i.machine_id]) {
                            temp[i.machine_id]={}
                        }
                        temp[i.machine_id][moment(i.date).format("DD-MM-YYYY")]=(i.oee).toFixed()
                    }
                    this.machineList=[...machineListTemp]
                    this.dateList=[...dateListTemp]
                    this.oeeData=temp
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        }
    },
    watch: {
        shift() {
            this.getOEEData();
        },
        machines() {
            this.getOEEData();
        },
        // from_date() {
        //     this.getOEEData();
        // },
        to_date() {
            this.getOEEData();
        },
    }
}
</script>