<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />  
        <v-row no-gutters>
            <v-col>
                <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"  flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-row no-gutters class="ma-3">
                        <!-- <v-col>
                            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                            <v-select  
                                v-if="!loading"
                                v-model="selectedMetric"
                                :items="metricsOption"
                                :label="$store.getters.getTextMap().metrics"
                                dense
                                outlined
                                item-text="label"
                                item-value="value"
                                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                required
                                multiple
                                :dark="$store.getters.getColorPalette().isDark"
                            ></v-select  >
                        </v-col> -->
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory rounded>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn>
                                <v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <div v-if="oeeData" ref="vis"></div>
                    <div v-else :style="[{'padding':'10px','display':'flex','justify-content':'center','color':$store.getters.getColorPalette().accentCode}]">
                        <v-icon :dark="$store.getters.getColorPalette().isDark" class="mr-3">mdi-database-remove-outline</v-icon><h3>{{ $store.getters.getTextMap().no_data_available }}</h3>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import axios from 'axios';
import moment from 'moment'
import Plotly from 'plotly.js'
export default {
    name: 'OEEMetricsGraph',
    props: ["shift", "machines", "from_date", "to_date"],
    components: {
        InfoAlert,
    },
    data() {
        return {
            showDismissibleAlert: false,
            loading:false,
            info: '',
            oeeData: [],
            graphData: {},
            graphType:'line',
            selectedMetric: ['avg_performance','avg_quality','avg_availability'],
            selectedMetricNameMap: {
                'avg_performance':"Performance",
                'avg_quality':"Quality",
                'avg_availability':"Availability",
            },
            metricsOption: [
                {label:"Performance",value:"avg_performance"},
                {label:"Quality",value:"avg_quality"},
                {label:"Availability",value:"avg_availability"},
            ],
            layout: {
                font:{
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                margin: {
                    t:0
                },
                plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                showlegend:false,
                autosize: true,
                hoverlabel:{
                    namelength:-1,
                },
                 xaxis: {
                    tickangle: -50,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },  
                yaxis: {
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    mounted() {
        this.getOEEData()
    },
    methods: {
        createChart() {
            this.graphData={}
            this.layout['title']=""
            this.layout['showlegend'] = true
            for(let i of this.oeeData){
                if (!(i.metric in this.graphData)) {
                    let type=this.graphType==1?'line':'bar'
                    this.graphData[i.metric]={
                        x: [],
                        y: [],
                        name:this.selectedMetricNameMap[i.metric],
                        type:type,
                        opacity:0.9,
                        marker: {
                            line: {
                                color: this.$store.getters.getColorPalette().accentCode,
                                width: 1
                            },
                        }
                    }
                }
                this.graphData[i.metric]['x'].push(moment(i.date).format('DD-MM-YYYY'))
                this.graphData[i.metric]['y'].push(i.data)
            }
            // let data = [{
            //     y: Object.values(this.oeeData),
            //     x: Object.keys(this.oeeData),
            //     type: 'bar',
            //     width:0.1,
            //     opacity: 0.9,
            //     name:this.selectedMetricNameMap[this.selectedMetric],
            //     marker: {
            //         line: {
            //             color: this.$store.getters.getColorPalette().accentCode,
            //             width: 1
            //         },
            //     }
            // }];     
            // Plotly.newPlot(this.$refs.vis, data, this.layout, {displaylogo: false});
            Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, {displaylogo: false});
        },
        getOEEData() {
            this.loading = true;
            const payload = {
                machines: this.machines,
                shift: this.shift,
                from_date: this.from_date,
                to_date: this.to_date,
                getAvgData: true,
                columns:['date']
           };
            axios.post(this.$store.state.api + "getAvgerageOEEDataBetweenDate", payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
            .then(response => {
                if (response.data.status == "success") {
                    let dbData = response.data.data;
                    let temp=[]
                    for (let i of dbData) {
                        temp.push(
                            {
                                metric:"avg_availability",
                                date: i.date,
                                data: ((i && i.avg_availability ? i.avg_availability : 0) * 100).toFixed()
                            },
                            {
                                metric:"avg_performance",
                                date: i.date,
                                data: ((i && i.avg_performance ? i.avg_performance : 0) * 100).toFixed()
                            },
                            {
                                metric:"avg_quality",
                                date: i.date,
                                data: ((i && i.avg_quality ? i.avg_quality : 0) * 100).toFixed()
                            }
                        );
                    }
                    this.oeeData=temp
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        }
    },
    watch: {
        shift() {
            this.getOEEData();
        },
        machines() {
            this.getOEEData();
        },
        from_date() {
            this.getOEEData();
        },
        to_date() {
            this.getOEEData();
        },
        oeeData() {
            this.createChart()
        },
        selectedMetric() {
            this.getOEEData()
        },
        graphType() {
            this.createChart()
        }
    }
}
</script>