<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">{{ $store.getters.getTextMap().add_downtime_reason }}</h2>
            </v-col>
        </v-row>
        <v-form ref="downtimeReasonForm" v-model="valid">
            <v-row v-if="!(downtimeBucket && downtimeBucket.machine_id && downtimeBucket.downtime_reason)">
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        v-if="!loading"
                        v-model="downtimeCategory"
                        :items="downtimeCategoriesOptions"
                        :label="$store.getters.getTextMap().category"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                        :rules="objectRules"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-select  
                        v-if="!loading"
                        v-model="form.downtime_reason"
                        :items="downtimeReasonsOptions"
                        :label="$store.getters.getTextMap().reason"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                        :rules="objectRules"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"  
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.remarks"
                        dense
                        outlined
                        :counter="100"
                       
                        :label="$store.getters.getTextMap().remarks"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-textarea
                        :dark="$store.getters.getColorPalette().isDark"  
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.details"
                        dense
                        outlined
                        :counter="200"
                        :label="$store.getters.getTextMap().details"
                        required
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="d-flex justify-end">
                    <v-btn v-if="!loading" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().submitbtnColor" small @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert';
import FormRules from '@/utillities/FormRules'
import axios from 'axios';
export default {
    name: 'AddDowntimeReason',
    props:['downtimeBucket'],
    components: {
        InfoAlert
    },
    data() {
        return {
            showDismissibleAlert:false,
            loading:false,
            info: '',
            form: {},
            valid:false,
            downtimeCategory:null,
            downtimeReasonsOptions:[],
            downtimeCategoriesOptions:[],
            rules: FormRules.rules,
            objectRules:FormRules.objectRules
        }
    },
    mounted() {
        this.getDowntimeCategories()
    },
    methods: {
        getDowntimeCategories() {
            this.loading=true
            axios.get(this.$store.state.api + "getDowntimeCategories",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let dbData = response.data.data
                let temp=[]
                for (let i of dbData) {
                    temp.push({"label": i.category, "value": i.category})
                }
                this.downtimeCategoriesOptions = temp
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        getDowntimeReasonsByCategory() {
            this.loading=true
            axios.post(this.$store.state.api + "getDowntimeReasonsByCategory",{category:this.downtimeCategory},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                let dbData = response.data.data
                let temp=[]
                for (let i of dbData) {
                    temp.push({"label": i.downtime_reason, "value": i.downtime_reason})
                }
                this.downtimeReasonsOptions = temp
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        onSubmit() {
            if (this.$refs.downtimeReasonForm.validate()) {
                let payload = Object.assign({}, this.downtimeBucket)
                payload["downtime_reason"]=this.form.downtime_reason
                payload["remarks"]=this.form.remarks
                payload["details"] = this.form.details
                axios.post(this.$store.state.api + "addDowntimeReason",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        this.$emit("update")
                        this.info = "Reason added successfully.";
                        this.showDismissibleAlert = true;
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
        }
    },
    watch: {
        downtimeCategory() { 
            this.getDowntimeReasonsByCategory()
        }
    }
}
</script>