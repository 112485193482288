<template>
    <v-container fluid>
        <v-row class="mb-1" no-gutters>
            <v-col>
                <h2 :style="{ 'color': $store.getters.getColorPalette().accentCode }">{{ $store.getters.getTextMap().oee }}</h2>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn 
                    @click="handleRouting('/machine_production_plan')"
                    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined
                    class="mr-2"  
                >
                 {{ $store.getters.getTextMap().production_planner }}
                 <!-- <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-file-tree</v-icon> -->
                </v-btn>
                <AddDowntimeReasonModal /> 
            </v-col>
        </v-row>
        <v-divider :dark="$store.getters.getColorPalette().isDark"/>
        <v-row no-gutters>
            <v-col>
                <OEEPanel/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import AddDowntimeReasonModal from "@/components/modals/oee/AddDowntimeReasonModal";
import OEEPanel from "@/components/panels/oee/OEEPanel.vue";
    export default {
        name:"OEE",
        components: {
            AddDowntimeReasonModal,
            OEEPanel
        },
        data() {
            return {
            }
        },
        methods: {
            handleRouting(route) {
                this.$router.push(route)
            }
        }
    }
</script>